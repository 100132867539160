<template>    
    <div style="background-color: #f2f7ff">
        <Sidebar></Sidebar>

        <div id="main">
            <header class="mb-3">
                <a href="#" class="burger-btn d-block d-xl-none">
                    <i class="bi bi-justify fs-3"></i>
                </a>
            </header>

            <div class="page-heading">
                <div class="page-title">
                    <div class="row">
                        <div class="col-12 order-md-1 order-last">
                            <h3>All Product</h3>
                            <p class="text-subtitle text-muted">List of all products</p>
                        </div>
                    </div>
                </div>
                <section class="section">
                    <div class="card">
                        <div class="card-header">
                            <router-link to="/mitra/add-product">Add New Product</router-link>
                        </div>
                        <div class="card-body">
                            <table class="table table-striped" id="table1">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Price Min</th>
                                        <th>Price Max</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in products" :key="product.id">
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.category }}</td>
                                        <td>Rp. {{ Number(product.min).toLocaleString() }}</td>
                                        <td>Rp. {{ Number(product.max).toLocaleString() }}</td>
                                        <td>
                                            <router-link :to="`/mitra/products/${product.id}`" class="btn btn-sm btn-primary">View</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>

            <footer>
                <div class="footer clearfix mb-0 text-muted">
                    <div class="float-start">
                        <p>2021 &copy; Rinyuakng</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>

import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios'

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            token: localStorage.getItem('token'),
            products: '',
            modalItem: {
                title: '',
                image: ''
            }
        }
    },
    methods: {
        getProducts() {
            axios.get(`mitra/my-product?token=${this.token}`)
                .then(res => {
                    this.products = res.data;
                })
                .catch(err => {
                    alert(err.response.data.message)
                });
        },
        renderDataTable() {
            let table1 = document.querySelector('#table1');
            let dataTable = new simpleDatatables.DataTable(table1);
        }
    },
    created() {
        this.getProducts();
    },
    mounted() {
        this.getProducts();
    },
}
</script>

<style>

.dataTable-wrapper.no-header .dataTable-container {
	border-top: 1px solid #d9d9d9;
}


.dataTable-top,
.dataTable-bottom {
	padding: 8px 10px;
}

.dataTable-top > div:first-child,
.dataTable-bottom > div:first-child {
	float: left;
}

.dataTable-top > div:last-child,
.dataTable-bottom > div:last-child {
	float: right;
}

.dataTable-input {
	padding: 6px 12px;
}

.dataTable-info {
	margin: 7px 0;
}


.dataTable-pagination a {
	border: 1px solid transparent;
	float: left;
	margin-left: 2px;
	padding: 6px 12px;
	position: relative;
	text-decoration: none;
	color: #333;
}

.dataTable-pagination a:hover {
	background-color: #d9d9d9;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
	background-color: #d9d9d9;
	cursor: default;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
	cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
	cursor: not-allowed;
	opacity: 0.4;
}

.dataTable-pagination .pager a {
	font-weight: bold;
}

/* TABLE */
.dataTable-table {
	max-width: 100%;
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
}


.dataTable-table > tfoot > tr > th {
	vertical-align: bottom;
	text-align: left;
	border-top: 1px solid #d9d9d9;
}

.dataTable-table th {
	vertical-align: bottom;
	text-align: left;
}

.dataTable-table th a {
	text-decoration: none;
	color: inherit;
}

.dataTable-sorter {
	display: inline-block;
	height: 100%;
	position: relative;
	width: 100%;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	right: 4px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	opacity: 0.2;
}

.dataTable-sorter::before {
	border-top: 4px solid #000;
	bottom: 0px;
}

.dataTable-sorter::after {
	border-bottom: 4px solid #000;
	border-top: 4px solid transparent;
	top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
	opacity: 0.6;
}

.dataTables-empty {
	text-align: center;
}

.dataTable-top::after, .dataTable-bottom::after {
	clear: both;
	content: " ";
	display: table;
}

.dataTable-dropdown {
	display: inline-flex;
	align-items: center;
}

.dataTable-dropdown label {
	white-space: nowrap;
	margin-left: 15px;
}

.card {
    overflow-x: auto;
}

</style>